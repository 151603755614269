<template>
	<div style="background: #FFFFFF; min-width: 1400px;">
		<div class="w-1 about fl-s-c">
			<el-breadcrumb class="w-1" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{$t('m.homebar')}}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/qualification',query:{pname,pid}}">{{pname}}</el-breadcrumb-item>
				<el-breadcrumb-item>{{$route.name == 'qualification'?$t('m.list'):$t('m.text')}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="fr-s-c w-1" style="margin-top: 30px;" v-if="$route.name == 'qualification'">
				<span class="c-title">{{pname}}</span>
				<el-divider class="row-line flex-1"></el-divider>
			</div>
			<div class="w-1 fr-b-s" style="margin-top: 20px; flex-wrap: wrap;"  v-if="$route.name == 'qualification'">
				<div class="fl-c zz-item pointer" v-for="(item,index) in list" :key="index" @click="toDetail(item.id,item.title_name)">
					<img :src="imgurl+item.url_image" class="zz-img">
					<span>{{item.title_name}}</span>
				</div>
				<div style="width: 375px;"></div>
				<div style="width: 375px;"></div>
				<div style="width: 375px;"></div>
			</div>
			<router-view></router-view>
			<p v-if="total==0  && $route.path == '/qualification'" style="margin: 50px; font-size: 24px;">{{$t('m.Norelevantcontent')}}</p>
			<!-- 分页器 -->
			<div class="w-1 fr-c my-pager" v-show="total >0 && $route.name == 'qualification'">
				<span v-if="$i18n.locale=='zh'">共{{Math.ceil(total/limit)}}页</span>
				<span v-else="$i18n.locale=='zh'">Total {{Math.ceil(total/limit)}} pages</span>
				<span style="margin-left: 0.625rem;" v-if="$i18n.locale=='zh'">页次：{{currentPage}}/{{Math.ceil(total/limit)}}页</span>
				<span style="margin-left: 0.625rem;" v-else>Page: {{currentPage}} of {{Math.ceil(total/limit)}}</span>
				<el-pagination style="margin-left: 0.625rem;" @current-change="handleCurrentChange"
					:current-page="currentPage"  :page-size="limit"
					layout="prev, pager, next, jumper" :total="total" :prev-text="$t('m.previouspage')" :next-text="$t('m.nextpage')">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import url from "../request/base";
	export default {
		data() {
			return {
					 imgurl: url.baseUrl,
				pid:0,
				pname: '',
				currentPage: 1,
				list:[],
				total:0,
				limit:12
			}
		},

		watch: {
			$route: {
				handler(newval,oldval) {
					if(newval.path=='/qualification'){
						console.log(newval,oldval)
						this.pid = newval.query.pid
						this.pname = newval.query.pname
						this.getList()
					}
				},
				deep: true
			},
		},
		created() {
			console.log(this.$route.query,'canshu')
			this.pname = this.$route.query.pname
			this.pid = this.$route.query.pid
			this.getList()
		},
		methods: {
			getList(){
				this.$api.home.getcList({
					id:this.pid,
					limit:this.limit,
					page:this.currentPage
				}).then(res=>{
					console.log(res,'分类列表')
					this.list = res.data.list
					this.total = res.data.total
				})
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.$nextTick(()=>{
					this.getList()
				})
			},
			toDetail(id,name){
				this.$router.push({
					path:'/qualification/qualitydetail',
					query:{
						id,name,pname:this.pname,pid:this.pid
					}
				})
			}
		}
	}
</script>
<style scoped="scoped">
	.about {
		width: 1200px;
		margin: auto;
	}

	.c-title {
		font-size: 24px;
		font-weight: bold;
	}

	.row-line {
		margin-left: 30px;
		background: #999999;
	}
	

	.zz-img {
		width: 375px;
		height: 282px;
		border: 1px solid #cccccc;
		object-fit: contain;
	}

	.zz-item {
		width: 375px;
		/* margin-right: 70px; */
		margin-bottom: 10px;
	}
	
	.zz-item

	.zz-item:nth-of-type(3n+3) {
		margin-right: 0;
	}

	.zz-item span {
		margin-top: 5px;
		font-size: 14px;
		color: #555555;
	}
	.my-pager{
		font-size: 14px!important;
		color: #606060;
		margin: auto;
		margin-top: 76px;
		height: 100px;
	}
	.zw{
		width: 450px;
	}
</style>
